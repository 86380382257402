import React from 'react'

import Seo from '../components/seo'
import Layout from '../components/layout'

class OemIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo title="404" />
      </Layout>
    )
  }
}

export default OemIndex

